<template>
  <input
    ref="input"
    :class="styles({ class: props.class })"
    :id="id"
    :name="name"
    :placeholder="placeholder"
    :disabled="disabled"
    :required="required"
    v-model="localModel"
    v-bind="$attrs"
  />
</template>

<script lang="ts" setup>
import { useVModel, useFocus } from "@vueuse/core";

const props = withDefaults(
  defineProps<{
    class?: any;
    id?: string;
    name?: string;
    placeholder?: string;
    disabled?: boolean;
    required?: boolean;
    modelValue?: any;
    autofocus?: any;
  }>(),
  {},
);
const input = ref<HTMLInputElement>();

useFocus(input, {
  initialValue: props.autofocus !== undefined,
});

const styles = tv({
  // base: "flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground placeholder:text-muted-foreground file:hover:cursor-pointer focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
  // base: "flex h-10 w-full rounded-md border-0 bg-secondary px-3 py-2 text-sm placeholder:text-muted-foreground focus-visible:outline-none focus-visible:brightness-110 focus-visible:read-only:brightness-100 disabled:cursor-not-allowed disabled:opacity-50",
  base: "relative w-full rounded-md outline-none bg-secondary text-left text-white shadow-sm hover:brightness-110 px-3 py-2 text-base leading-6",
});

const emits = defineEmits<{
  "update:modelValue": [value: any];
}>();

const localModel = useVModel(props, "modelValue", emits);
</script>
